import type { TRuleWithTheme } from 'styles/theme';

import { getPasswordCharColor } from '../../../utilities';

export const char: TRuleWithTheme<{
    char: string;
}> = ({ theme, char }) => ({
    ...theme.typography.ShowPassword,

    color: getPasswordCharColor(char, theme),
});
