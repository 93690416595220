import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

import { style as baseStyle } from '../TextInput/TextInput.rules';

export const style = combineRules(baseStyle, (({ theme }) => {
    const style = {
        ...theme.typography.ShowPassword,

        whiteSpace: 'nowrap',
        overflow: 'auto',

        msOverflowStyle: 'none',
        scrollbarWidth: 'none',

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    };
    return {
        '&.ant-input': style,
        '&.ant-input-affix-wrapper > .ant-input': style,
    };
}) as TRuleWithTheme);
