import type { TRule } from 'fela';

import colors from './colors';

const breakpoints = {
    sm: 768,
    md: 1024,
    lg: 1440,
};

const zIndexes = {
    cta: '1000',
    mobileMenu: '999',
    mobileMenuOverlay: '998',
    updateFormButtons: '997',
};

const widths = {
    menu: 220,
};

const typography = {
    ParagraphSmall: {
        fontSize: '0.875rem', // 14px
        fontWeight: 400,
        lineHeight: '1.43em', // ~20px
        letterSpacing: '0.0143em', // ~0.2px
    },
    Crypted: {
        letterSpacing: '0.125rem',
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
    },
    ShowPassword: {
        fontFamily: 'DM Mono, monospace',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 400,
        letterSpacing: '0.01875rem',
    },
};

const theme = {
    fontFamily: 'Soleil, sans-serif',
    colors,
    breakpoints,
    zIndexes,
    widths,
    typography,
};

export default theme;

export type Theme = typeof theme;

export type TRuleWithTheme<T = {}> = TRule<
    {
        theme: Theme;
    } & T
>;
