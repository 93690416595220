import type { TRuleWithTheme } from 'styles/theme';

import type { CopyButtonType } from '../CopyButton';

export { container, text } from '../Text/Text.rules';

const iconSize = 1.5;

export const icon: TRuleWithTheme = () => ({
    cursor: 'pointer',

    flexShrink: 0,

    width: `${iconSize}rem`,
    height: `${iconSize}rem`,
});

export const secretContainer: TRuleWithTheme<{ type: `${CopyButtonType}`; show: boolean }> = ({ type, show }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.375rem',

    ...(type === 'secureTextArea' &&
        show && {
            width: '100%',
            alignItems: 'flex-start',
        }),
});

export const disabledWarningText: TRuleWithTheme = () => ({
    maxWidth: '8.5rem',
});

export const cryptedText: TRuleWithTheme = ({ theme }) => ({
    ...theme.typography.Crypted,
});
