import { createApiActions } from '@ackee/redux-utils';

type RequestPayloadSAASCheckout = {
    couponId?: string;
    clientReferenceId?: string;
};

type SuccessPayloadSAASCheckout = {
    url: string;
};

type FailurePayloadSAASCheckout = {
    error: unknown;
};

export const saasCheckoutActions = createApiActions<
    'saas-upgrade/SAAS_CHECKOUT',
    RequestPayloadSAASCheckout,
    SuccessPayloadSAASCheckout,
    FailurePayloadSAASCheckout
>('saas-upgrade/SAAS_CHECKOUT');
